import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Contact from '../views/Contact.vue';
import Links from '../views/Links.vue';
import LinkGroupEditor from '../views/LinkGroupEditor.vue';
import LinkRowEditor from '../views/LinkRowEditor.vue';
import LinkEditor from '../views/LinkEditor.vue';
import NotFound404 from '../views/NotFound404.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/links',
        name: 'Links',
        component: Links
    },
    {
        path: '/linkgroup/:id',
        name: 'LinkGroupEditor',
        component: LinkGroupEditor
    },
    {
        path: '/linkrow/:id',
        name: 'LinkRowEditor',
        component: LinkRowEditor
    },
    {
        path: '/link/:id/:row/:position',
        name: 'LinkEditor',
        component: LinkEditor
    },
    {
        path: '/:pathMatch(.*)',
        name: '404 Not Found',
        component: NotFound404
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
