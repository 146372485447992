<template>
    <li>
        <span>
            <Link
                v-for="(link, index) in sortedLinks"
                :key="index"
                :link="link"
                :spacer="index > 0 ? true : false"
                :row="linkRow.row"
                :linkGroup="linkGroup"
            />
        </span>
        <span class="row-actions" v-if="store.state.editEnabled">
            <a class="material-icons">edit</a>
            <a
                class="material-icons"
                @click.prevent.stop="
                addLinkToRow(linkGroup.id, linkGroup, linkRow.row)
                "
            >add_circle</a>
            <a class="material-icons">delete</a>
        </span>
    </li>
</template>

<script setup>
import { inject, defineProps, computed } from 'vue';
import Link from './Link.vue';
import { useLinkGroup } from '@/firebase';

const { addLinkToRow } = useLinkGroup();

const props = defineProps({
    linkRow: Object,
    linkGroup: Object
});

const store = inject('store');

const sortedLinks = computed(() => {
    if (
        props.linkRow === undefined ||
        props.linkRow === null ||
        props.linkRow.links === undefined ||
        props.linkRow.links === null
    ) {
        return null;
    }

    return [...props.linkRow.links].sort((a, b) => {
        return Number(a.position) - Number(b.position);
    });
});
</script>

<style>
@import "~material-design-icons/iconfont/material-icons.css";
.lgrp li {
    border-bottom: 2px #888 dotted;
    padding: 2px;
    padding-left: 10px;
    display: grid;
    grid-template-columns: auto max-content;
}

.lgrp li:last-child {
    border-bottom: 0px #888 none;
}

.row-actions {
    align-self: center;
    user-select: none;
}

.row-actions a {
    margin-left: 10px;
}

.row-actions a:link {
    color: black;
    text-decoration: none;
}
.row-actions a:visited {
    color: black;
    text-decoration: none;
}
.row-actions a:hover {
    color: blue;
    text-decoration: none;
}
.row-actions a:active {
    color: black;
    text-decoration: none;
}
</style>