import { reactive, readonly } from 'vue';

const state = reactive({
    editEnabled: false
});

const toggleEditEnabled = () => {
    state.editEnabled = !state.editEnabled;
};

export default {
    state: readonly(state),
    toggleEditEnabled
};
