<template>
    <nav class="sitemenu">
        <div>
            <router-link to="/">Home</router-link>
        </div>
        <div>
            <router-link to="/contact">Contact</router-link>
        </div>
        <div>
            <router-link to="/links">Links</router-link>
        </div>
        <div class="sitemenu-spacer">&#160;</div>
        <div v-if="isSignedIn">
            <Avatar :src="user.photoURL" />
            <a href="/signout" @click.prevent="signOut">Sign out</a>
        </div>
        <div v-else>
            <a href="/signin" @click.prevent="signIn">Sign in</a>
        </div>
    </nav>
</template>

<script setup>
import { useAuth } from '@/firebase';
import Avatar from './Avatar.vue';
const { user, signIn, signOut, isSignedIn } = useAuth();
</script>

<style>
nav.sitemenu {
    display: grid;
    column-count: 1;
}
@media (min-width: 960px) {
    nav.sitemenu {
        display: grid;
        column-count: 5;
        grid-template-columns: [col1] 140px [col2] 140px [col3] 140px [col4] auto [col5] 140px [colEnd];
    }
}
.sitemenu {
    width: 100%;
    background-color: #606060;
    margin: 0px;
    padding: 0px;
    border-spacing: 0px;
}
.sitemenu div {
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: bold;
    border-top: 1px solid #868686;
    border-left: 1px solid black;
    border-right: 1px solid #868686;
    padding-top: 4px;
    padding-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
@media (max-width: 960px) {
    .sitemenu div {
        border-bottom: 1px solid black;
    }
    .sitemenu-spacer {
        display: none;
    }
}
.sitemenu a:link {
    color: #ddd;
    text-decoration: none;
}
.sitemenu a:visited {
    color: #ddd;
    text-decoration: none;
}
.sitemenu a:hover {
    color: #eee;
    text-decoration: underline;
}
.sitemenu a:active {
    color: #ddd;
    text-decoration: none;
}
</style>