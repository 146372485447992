<template>
    <header id="sitelogo">
        <h1>
            <router-link to="/">Crankedup.com</router-link>
        </h1>
    </header>
</template>

<script>
</script>

<style>
header {
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-image: repeating-linear-gradient(0deg, black, #444444 100px);
    border-width: 0px;
    border-bottom: 1px black solid;
}
header h1 {
    color: white;
    font-family: 'Segoe UI', 'Trebuchet MS', Arial, Verdana, Helvetica,
        sans-serif;
    font-size: 60px;
    font-weight: bold;
    margin: 3px 10px 6px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
#sitelogo a:link {
    color: white;
    text-decoration: none;
}
#sitelogo a:visited {
    color: white;
    text-decoration: none;
}
#sitelogo a:hover {
    color: white;
    text-decoration: underline;
}
#sitelogo a:active {
    color: white;
    text-decoration: none;
}
</style>