<template>
    <section class="linkcolumn">
        <LinkGroup
            v-for="(linkGroup, index) in linkGroups"
            :key="index"
            :linkGroup="linkGroup"
        />
        <button
            v-if="store.state.editEnabled === true"
            @click.prevent="addLinkGroup(props.column)"
        >
            Add group
        </button>
    </section>
</template>

<script setup>
import { inject, defineProps } from 'vue';
import LinkGroup from './LinkGroup.vue';
import { useLinkGroups } from '@/firebase';

const props = defineProps({
    linkGroups: Object,
    column: Number
});

const store = inject('store');

const { addLinkGroup } = useLinkGroups();
</script>

<style>
</style>