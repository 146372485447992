<template>
    <div class="links">
        <link-column :linkGroups="linkGroupsCol1" :column="1" />
        <link-column :linkGroups="linkGroupsCol2" :column="2" />
        <link-column :linkGroups="linkGroupsCol3" :column="3" />
    </div>
</template>

<script setup>
import LinkColumn from '../components/LinkColumn.vue';
import { useLinkGroups } from '@/firebase';
const { linkGroupsCol1, linkGroupsCol2, linkGroupsCol3 } = useLinkGroups();
</script>

<style>
div.links {
    display: grid;
    column-count: 1;
    grid-template-columns: [col1] 1fr [colEnd];
}
@media (min-width: 960px) {
    div.links {
        column-count: 3;
        column-gap: 10px;
        grid-template-columns: [col1] 1fr [col2] 1fr [col3] 1fr [colEnd];
    }
}
div.links {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
    padding: 0px;
    border-spacing: 0px;
    font-family: 'Trebuchet MS', 'Segoe UI', Arial, Verdana, Helvetica,
        sans-serif;
    font-size: 20px;
}
</style>