<template>
    <form v-if="linkGroup !== null && linkGroup !== undefined">
        <table>
            <tr>
                <td><label>Title</label></td>
                <td><input v-model="linkGroup.title" type="text" /></td>
            </tr>
            <tr>
                <td><label>Column</label></td>
                <td>
                    <select v-model.number="linkGroup.column">
                        <option value="1">left</option>
                        <option value="2">middle</option>
                        <option value="3">right</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td><label>Row</label></td>
                <td><input v-model.number="linkGroup.row" type="number" /></td>
            </tr>
            <tr>
                <td><label>Visibility</label></td>
                <td>
                    <select v-model="linkGroup.visibility">
                        <option>public</option>
                        <option>private</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <input
                        type="button"
                        value="Save"
                        @click.prevent.stop="
                            saveLinkGroup(docId, linkGroup);
                            router.push({ name: 'Links' });
                        "
                    />
                    <input
                        type="button"
                        value="Cancel"
                        @click.prevent.stop="router.push({ name: 'Links' })"
                    />
                </td>
            </tr>
        </table>
    </form>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useLinkGroup } from '@/firebase';

const router = useRouter();
const route = useRoute();
const docId = route.params.id;
const { linkGroup, loadLinkGroup, saveLinkGroup } = useLinkGroup();
loadLinkGroup(docId);
</script>

<style>
form button {
    margin: 50px;
}
</style>