<template>
    <span
        v-if="store.state.editEnabled === true"
        @click.prevent="store.toggleEditEnabled"
        class="adminedit"
        >&#128221;</span
    >
    <img class="avatar" :src="src" @click.prevent="store.toggleEditEnabled" />
</template>

<script setup>
import { inject, defineProps } from 'vue';

defineProps({
    src: { type: String, default: '' }
});

const store = inject('store');
</script>

<style>
.avatar {
    vertical-align: middle;
    margin: 0px;
    margin-right: 7px;
    width: 16px;
    height: 16px;
    border-radius: 30%;
}
.adminedit {
    margin-right: 7px;
}
</style>