<template>
    <div class="lgrp">
        <div class="lgrp-title">
            {{
                linkGroup.title
            }}
            <span
                class="lgrp-actions"
                v-if="store.state.editEnabled === true"
            >
                <router-link
                    class="material-icons"
                    :to="{
                        name: 'LinkGroupEditor',
                        params: {
                            id: linkGroup.id
                        }
                    }"
                >edit</router-link>
                <a
                    class="material-icons"
                    @click.prevent.stop="
                    addRowToLinkGroup(linkGroup.id, linkGroup)
                    "
                >add_circle</a>
                <a class="material-icons" @click.prevent.stop>restore_page</a>
                <a class="material-icons" @click.prevent.stop>delete</a>
            </span>
        </div>
        <ul v-if="sortedRows && sortedRows.length > 0">
            <LinkRow
                v-for="(row, index) in sortedRows"
                :key="index"
                :linkRow="row"
                :linkGroup="linkGroup"
            />
        </ul>
    </div>
</template>

<script setup>
import { inject, defineProps, computed } from 'vue';
import LinkRow from './LinkRow.vue';
import { useLinkGroup } from '@/firebase';

const props = defineProps({
    linkGroup: Object
});

const store = inject('store');

const sortedRows = computed(() => {
    if (
        props.linkGroup === undefined ||
        props.linkGroup === null ||
        props.linkGroup.linkRows === undefined ||
        props.linkGroup.linkRows === null
    ) {
        return null;
    }

    return [...props.linkGroup.linkRows].sort((a, b) => {
        return Number(a.row) - Number(b.row);
    });
});

const { addRowToLinkGroup } = useLinkGroup();
</script>

<style>
@import "~material-design-icons/iconfont/material-icons.css";
.lgrp {
    margin: 0px 0px 9px 0px;
}
.lgrp-title {
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    background: #bfd1e1;
    margin: 0px;
    padding: 2px 0px 1px 0px;
    display: grid;
    grid-template-columns: auto max-content;
}
.lgrp-actions {
    margin-right: 12px;
    user-select: none;
}
.lgrp-actions a {
    margin-left: 10px;
}
.lgrp-actions a:link {
    color: black;
    text-decoration: none;
}
.lgrp-actions a:visited {
    color: black;
    text-decoration: none;
}
.lgrp-actions a:hover {
    color: blue;
    text-decoration: none;
}
.lgrp-actions a:active {
    color: black;
    text-decoration: none;
}
.lgrp ul {
    list-style-type: none;
    border-radius: 0px 0px 10px 10px;
    background: #e8e8e8;
    margin: 0px;
    padding: 2px 10px 2px 10px;
}
</style>