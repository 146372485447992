<template>
    <form v-if="link !== null && link !== undefined">
        <label>Title</label>
        <input v-model="link.title" type="text" />

        <label>href</label>
        <input v-model="link.href" type="url" />

        <label>Position</label>
        <input v-model.number="link.position" type="number" />

        <label>img source</label>
        <input v-model="link.img" type="url" />

        <div class="buttons">
            <input
                type="button"
                value="Save"
                @click.prevent.stop="
                saveLink(docId, row, position, link);
                router.push({ name: 'Links' });
                "
            />
            <input
                type="button"
                value="Cancel"
                @click.prevent.stop="router.push({ name: 'Links' })"
            />
        </div>
    </form>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useLinkGroup } from '@/firebase';

const router = useRouter();
const route = useRoute();
const docId = route.params.id;
const row = route.params.row;
const position = route.params.position;
const { link, loadLink, saveLink } = useLinkGroup();
loadLink(docId, row, position);
</script>

<style>
form {
    font-size: 16pt;
    max-width: 600px;
    padding: 20px;
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 10px;
    row-gap: 1em;
}

input {
    font-size: 16pt;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
    margin: 0px 10px;
}

.buttons {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    vertical-align: middle;
    margin-top: 10px;
}
</style>