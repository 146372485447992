<template>
    <site-header />
    <navbar />
    <main>
        <router-view />
    </main>
</template>

<script setup>
import { provide } from 'vue';
import store from '@/store';
import SiteHeader from '@/components/SiteHeader.vue';
import Navbar from '@/components/Navbar.vue';

provide('store', store);
</script>

<style>
body {
    margin: 6px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 12px;
}
main {
    width: 100%;
    margin: 0px;
    padding: 0px;
}
</style>
