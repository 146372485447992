import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ref, computed, onUnmounted } from 'vue';

// firebase init
const firebaseConfig = {
    apiKey: 'AIzaSyCOToqF8_ti2wNNXIeRQTUAk8IEYhVplmA',
    authDomain: 'crankedup-vue.firebaseapp.com',
    projectId: 'crankedup-vue',
    storageBucket: 'crankedup-vue.appspot.com',
    messagingSenderId: '1013752290390',
    appId: '1:1013752290390:web:3f1c77875bf80b5f192ff7'
};
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();

// Authentication
export function useAuth() {
    const user = ref(null);

    const unsubscribe = auth.onAuthStateChanged(_user => (user.value = _user));
    onUnmounted(unsubscribe);

    const isSignedIn = computed(() => user.value !== null);

    const signIn = async () => {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        await auth.signInWithPopup(googleProvider);
    };
    const signOut = () => auth.signOut();

    return { user, isSignedIn, signIn, signOut };
}

export function useLinkGroup() {
    const linkGroupCollection = db.collection('linkGroups');

    const linkGroup = ref(null);
    const link = ref(null);

    const loadLinkGroup = docId => {
        var docRef = linkGroupCollection.doc(docId);
        docRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    console.log('Document data:', doc.data());
                    linkGroup.value = doc.data();
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                }
            })
            .catch(error => {
                console.log('Error getting document:', error);
            });
    };

    const saveLinkGroup = (docId, linkGroup) => {
        linkGroupCollection
            .doc(docId)
            .update({
                title: linkGroup.title,
                column: linkGroup.column,
                row: linkGroup.row,
                visibility: linkGroup.visibility
            })
            .then(() => {});
    };

    const addRowToLinkGroup = (docId, linkGroup) => {
        if (linkGroup.linkRows === null || linkGroup.linkRows === undefined) {
            linkGroup.linkRows = [];
        }
        let highest = Math.max.apply(
            Math,
            linkGroup.linkRows.map(function (o) {
                return o.row;
            })
        );
        if (highest < 0) {
            highest = 0;
        }
        linkGroup.linkRows.push({ row: highest + 10, links: [] });
        linkGroupCollection
            .doc(docId)
            .update({
                linkRows: linkGroup.linkRows
            })
            .then(() => {});
    };

    const addLinkToRow = (docId, linkGroup, row) => {
        if (linkGroup.linkRows === null || linkGroup.linkRows === undefined) {
            return;
        }
        var rowObj = linkGroup.linkRows.find(o => {
            return o.row === row;
        });
        if (rowObj === null || rowObj === undefined) {
            return;
        }
        if (rowObj.links === null || rowObj.links === undefined) {
            rowObj.links = [];
        }
        let highest = Math.max.apply(
            Math,
            rowObj.links.map(function (o) {
                return o.position;
            })
        );
        if (highest < 0) {
            highest = 0;
        }
        rowObj.links.push({
            position: highest + 10,
            title: 'untitled',
            href: '',
            img: ''
        });
        linkGroupCollection
            .doc(docId)
            .update({
                linkRows: linkGroup.linkRows
            })
            .then(() => {});
    };

    const loadLink = (docId, row, position) => {
        var docRef = linkGroupCollection.doc(docId);
        docRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    let group = doc.data();

                    if (
                        group !== null &&
                        group.linkRows !== null &&
                        group.linkRows !== undefined
                    ) {
                        let rowObj = group.linkRows.find(o => {
                            return Number(o.row) === Number(row);
                        });
                        if (
                            rowObj !== null &&
                            rowObj !== undefined &&
                            rowObj.links !== null &&
                            rowObj.links !== undefined
                        ) {
                            link.value = rowObj.links.find(o => {
                                return Number(o.position) === Number(position);
                            });
                        }
                    }
                } else {
                    // doc.data() will be undefined in this case
                }
            })
            .catch(() => {});
    };

    const saveLink = (docId, row, position, link) => {
        var docRef = linkGroupCollection.doc(docId);
        docRef.get().then(doc => {
            if (doc.exists) {
                let group = doc.data();

                if (
                    group !== null &&
                    group.linkRows !== null &&
                    group.linkRows !== undefined
                ) {
                    let rowObj = group.linkRows.find(o => {
                        return Number(o.row) === Number(row);
                    });
                    if (
                        rowObj !== null &&
                        rowObj !== undefined &&
                        rowObj.links !== null &&
                        rowObj.links !== undefined
                    ) {
                        let linkObj = rowObj.links.find(o => {
                            return Number(o.position) === Number(position);
                        });
                        if (linkObj !== null && linkObj !== undefined) {
                            linkObj.title = link.title;
                            linkObj.href = link.href;
                            linkObj.position = link.position;
                            linkObj.img = link.img;

                            docRef
                                .update({ linkRows: group.linkRows })
                                .then(() => {});
                        }
                    }
                }
            }
        });
    };

    return {
        linkGroup,
        link,
        loadLinkGroup,
        saveLinkGroup,
        addRowToLinkGroup,
        addLinkToRow,
        loadLink,
        saveLink
    };
}

// Link groups
export function useLinkGroups() {
    const publicVisibility = ['public'];
    const publicAndPrivateVisibility = ['public', 'private'];

    const linkGroupsCollection = db.collection('linkGroups');
    const linkGroupsPublicQuery = linkGroupsCollection
        .where('visibility', 'in', publicVisibility)
        .orderBy('column');
    const linkGroupsPublicAndPrivateQuery = linkGroupsCollection
        .where('visibility', 'in', publicAndPrivateVisibility)
        .orderBy('column');

    // const linkGroups = ref([]);
    const linkGroupsCol1 = ref([]);
    const linkGroupsCol2 = ref([]);
    const linkGroupsCol3 = ref([]);

    let unsubscribeLinkGroupsQuery = null;
    const unsubscribeAuth = auth.onAuthStateChanged(_user => {
        if (unsubscribeLinkGroupsQuery !== null) {
            unsubscribeLinkGroupsQuery();
        }

        if (_user !== null) {
            unsubscribeLinkGroupsQuery =
                linkGroupsPublicAndPrivateQuery.onSnapshot(snapshot =>
                    processLinkGroupsQuerySnapshot(snapshot)
                );
        } else {
            unsubscribeLinkGroupsQuery = linkGroupsPublicQuery.onSnapshot(
                snapshot => processLinkGroupsQuerySnapshot(snapshot)
            );
        }
    });

    function processLinkGroupsQuerySnapshot(snapshot) {
        let linkGroups = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        linkGroupsCol1.value = linkGroups
            .filter(item => item.column === 1)
            .sort(function (a, b) {
                return a.row - b.row;
            });
        linkGroupsCol2.value = linkGroups
            .filter(item => item.column === 2)
            .sort(function (a, b) {
                return a.row - b.row;
            });
        linkGroupsCol3.value = linkGroups
            .filter(item => item.column === 3)
            .sort(function (a, b) {
                return a.row - b.row;
            });
    }

    onUnmounted(() => {
        unsubscribeAuth;
        if (unsubscribeLinkGroupsQuery !== null) {
            unsubscribeLinkGroupsQuery();
        }
    });

    const { isSignedIn } = useAuth();

    const addLinkGroup = column => {
        if (!isSignedIn.value) return;

        linkGroupsCollection.add({
            title: 'untitled',
            column: column,
            row: 999,
            visibility: 'private'
        });
    };

    return {
        linkGroupsCol1,
        linkGroupsCol2,
        linkGroupsCol3,
        addLinkGroup
    };
}
