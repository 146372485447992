<template>
    <span class="spacer" v-if="spacer === true">&#160;-&#32;</span>
    <img
        v-if="link.img !== undefined && link.img != null && link.img !== ''"
        :src="link.img"
        alt="link img"
    />
    <a target="_blank" rel="noopener noreferrer" :href="link.href">
        {{
            link.title
        }}
    </a>
    <span class="link-actions" v-if="store.state.editEnabled === true">
        <router-link
            class="material-icons"
            :to="{
                name: 'LinkEditor',
                params: {
                    id: linkGroup.id,
                    row: row,
                    position: link.position
                }
            }"
        >edit</router-link>
        <a class="material-icons">delete</a>
    </span>
</template>

<script setup>
import { inject, defineProps } from 'vue';

const store = inject('store');

defineProps({
    link: Object,
    spacer: Boolean,
    row: Number,
    linkGroup: Object
});
</script>

<style>
@import "~material-design-icons/iconfont/material-icons.css";

.links a:link {
    color: black;
    text-decoration: none;
}
.links a:visited {
    color: #5d5d5d;
    text-decoration: none;
}
.links a:hover {
    color: blue;
    text-decoration: underline;
}
.links a:active {
    color: black;
    text-decoration: underline;
}
.links img {
    width: 30px;
    height: auto;
    border: 1px black solid;
    vertical-align: middle;
    margin: 0px;
    margin-right: 7px;
}
.link-actions {
    margin-left: 7px;
}
.link-actions a:link {
    color: black;
    text-decoration: none;
}
.link-actions a:visited {
    color: black;
    text-decoration: none;
}
.link-actions a:hover {
    color: blue;
    text-decoration: none;
}
.link-actions a:active {
    color: black;
    text-decoration: none;
}
</style>